import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  display: flex;
  flex: 1;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  max-width: 50vw;
  filter: blur(${({ loading })=>{
    return loading === 'lazy' ? '20px' : 0;
  }});

  transition: filter 1.5s;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    width: 100%;
    max-height: 248px;
    min-height: 248px;
  }
`;

const Image = (props)=>{
  const [loading, setLoading] = useState('lazy');
  const ref = useRef<HTMLDivElement | null>(null);

  function lazyLoad(image) {
    if (image[0].intersectionRatio > 0) {
      image[0].target.src = image[0].target.dataset.src;
      setLoading(null);
    }
  }

  useEffect(()=>{
    if (!ref.current){
      return;
    }
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      lazyLoad,
      { threshold: [1], rootMargin: '100%' }
    );
    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [ref]);

  return (
    <StyledImage
      ref={ref}
      // eslint-disable-next-line react/prop-types
      data-src={`/imgs/${props['dataSrc']}.jpg`}
      {...props}
      loading={loading} />
  );
};

export default Image;
