/* eslint-disable max-len */
import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80px;

  background: ${({ backGroundColor })=>backGroundColor
    ? backGroundColor
    : 'linear-gradient(90.02deg, #FEAF6B 0.49%, #FE9090 52.18%, #B982EA 99.14%)'};
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    padding: 0 16px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-basis: 10%;
  justify-content: ${props=>props.centerJustify ? props.centerJustify :'center'};
  align-items: center;
`;
const HeaderCenter = styled.div`
  display: flex;
  flex-basis: 80%;
  justify-content: ${props=>props.centerJustify ? props.centerJustify :'center'};
  align-items: center;
`;
const HeaderRight = styled.div`
  display: flex;
  flex-basis: 10%;
  justify-content: flex-start;
  align-items: center;
`;

export interface HeaderProps {
  leftContent?: unknown;
  centerContent?: unknown;
  rightContent?: unknown;
  theme?: unknown;
  className?: unknown;
  backGroundColor?: string
}

const Header = (props: HeaderProps)=> {
  return (
    <StyledHeader
      backGroundColor={props.backGroundColor}
      className={props.className}>
      <HeaderLeft>
        {props.leftContent}
      </HeaderLeft>
      <HeaderCenter>
        {props.centerContent}
      </HeaderCenter>
      <HeaderRight>
        {props.rightContent}
      </HeaderRight>
    </StyledHeader>
  );
};

export default Header;
