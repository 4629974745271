import styled from 'styled-components';

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(8, 8, 9, 0.2);
  z-index: 90;
`;

export default BackDrop;
