import styled from 'styled-components';

const TextLink = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-decoration-line: underline;

  color: #F8933B;
  cursor: pointer;
  margin: 0 5px;
`;

export default TextLink;

