import BackDrop from '@src/components/BackDrop';
import styled from 'styled-components';
import { Box, Container as ContainerDefault, Icon } from '..';

const StyledModal = styled(Box)<{maxWidth: string }>`
  flex: initial;
  background-color: #FCF1E8;
  min-width: 836px;
  max-width: 1232px;
  min-height: 408px;
  max-height: max-width: ${({ maxWidth })=>maxWidth};
  padding: 16px;
  border-radius: 10px;
  justify-content: flex-start;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    max-width: 100vw;
    min-width: 100vw;
    min-height: 100%;
  }
`;

const Container = styled(ContainerDefault)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 64px;
  z-index: 90;
  
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    justify-content: flex-start;
    top: 10%;
    padding: 0;
  }
`;

interface ModalProps{
  children: unknown,
  closeModal: ()=>void,
  maxWidth?: string,
}

const Modal = ({ children, closeModal, maxWidth }: ModalProps)=>{
  return (<>
    <BackDrop onClick={closeModal}/>
    <Container>
      <StyledModal
        maxWidth={maxWidth}
      >
        <Box
          styleSheet={{
            width: '100%',
            alignItems: 'flex-end',
            flex: 'initial'
          }}>
          <Icon
            onClick={closeModal}
            src='close-x'
            active={true}/>
        </Box>
        {children}
      </StyledModal>
    </Container>
  </>);
};

export default Modal;
