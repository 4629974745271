import renderCSS from '@src/utils/renderCSS';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: ${({ row })=>row ? 'row' : 'column'};
  height: calc(var(--vh, 1vh) * 100);

  background-color: ${({ theme, color = 'primary', value = '100' })=>{
    return theme.background[color][value];
  }};
  ${props => props.styleSheetContainer}
`;

StyledContainer.defaultProps = {
  theme: {
    background: {
      primary: {
        default: '#FCF1E8',
        '100': '#FFFBF7',
        '200': '#FCF7F2',
        '300': '#FCF1E8',
      },
      secundary: {
        default: '#EBF1FA',
        '100': '#FCFCFC',
        '200': '#FAFCFF',
        '300': '#EBF1FA',
      }
    }
  }
};

/**
 * Primary UI component for user interaction
 */
export interface ContainerProps {
  children: unknown;
  theme?: object,
  color?: string;
  value?: string;
  row?: unknown;
  styleSheet?: unknown;
  className?: unknown;
}

const Container = (props: ContainerProps)=>{
  const styleSheetContainer = css`
    ${renderCSS(props.styleSheet, 'xs')}
  `;

  return <StyledContainer
    className={props.className}
    row={props.row}
    styleSheetContainer={styleSheetContainer}>
    {props.children}
  </StyledContainer>;
};

export default Container;
