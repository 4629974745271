import styled from 'styled-components';

const StyledProgressbar = styled.div`
  background-color: ${({ level })=>level ? '#D6E0EE' :'#FCF1E8;'};
  min-width: 100%;
  height: ${({ level }) => level ? '3px' : '8px'};
  color: transparent;
  border-radius: 10px;
  margin: ${({ level }) => level ? '18.2px 0' : '24px 0 18px 0'};
`;

const SytledPercent = styled.div`
  background-color: ${({ color })=>color ? color :'#2C3143'} ;
  width: ${({ percent }) => percent ? percent + '%' : 0};
  height: ${({ level }) => level ? '5px' : '8px'};
  border-radius: 10px;
  transition:width 0.4s ease-in-out;

  margin: ${({ level }) => level ? '-1px 0' : '0'};
  &::before, &::after{
    box-sizing: inherit;
    content: "";
    display: table;
    clear: both;
  }
`;

export interface ProgressbarProps {
  percent: number | string;
  color?: string;
  level?: boolean;
}

const Progressbar = ({ percent, color, level }: ProgressbarProps) => {
  return (
    <StyledProgressbar
      level={level}>
      <SytledPercent
        percent={percent}
        color={color}
        level={level}>
        teste 2
      </SytledPercent>
    </StyledProgressbar>
  );
};

export default Progressbar;

