/* eslint-disable max-statements */
import renderCSS from '@src/utils/renderCSS';
import styled, { css } from 'styled-components';

const StyledText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #5F698C;
  ${props => props.styleSheetText}

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-size: 14px;
  }
`;

const p3 = styled(StyledText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;

const plogin = styled(StyledText)`font-family: 'Barlow';
  font-family: 'Barlow';
  color: #929AB3;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

const h1 = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 48px;
  /* or 133% */

  display: flex;
  align-items: center;

  color: #5F698C;
  margin-bottom: 17px;

  ${props => props.styleSheetText}

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;

    color: #2C3143;
    margin-bottom: 24px;
  }
`;

const h2 = styled(StyledText)`
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 30px;
  /* or 115% */
  ${props => props.styleSheetText}
`;

const h3 = styled.h3`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 30px;
  /* or 115% */

  display: flex;
  align-items: center;

  color: #5F698C;
  ${props => props.styleSheetText}
`;

const h4 = styled.h4`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #5F698C;
  text-align: center;
  ${props => props.styleSheetText}
  /* or 115% */
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-size: 20px;
    justify-content: center;
  }
`;

const h5 = styled.h5`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #5F698C;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    justify-content: center;
  }
  ${props => props.styleSheetText}
`;

const h2Span = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 30px;

  margin: 0 5px;
  /* or 115% */

  display: flex;
  align-items: center;

  color: #2C3143;
  ${props => props.styleSheetText}
`;

const h1Error = styled.h1`

font-family: Open Sans;
font-size: 62px;
font-weight: 600;
line-height: 44px;
letter-spacing: 0em;
text-align: center;
/* or 128% */

display: flex;
align-items: center;

color: #FCF1E8;
margin: 8px 0;
`;

const h2Error = styled.h2`

font-family: Open Sans;
font-size: 22px;
font-weight: 600;
line-height: 44px;
letter-spacing: 0em;
text-align: center;
/* or 128% */

display: flex;
align-items: center;

color: #FCF1E8;
margin: 8px 0 24px 0;
`;

const msgError = styled.h2`

font-family: Lora;
font-size: 26px;
font-weight: 500;
line-height: 35px;
letter-spacing: 0em;
text-align: center;
/* or 128% */

display: flex;
align-items: center;

color: #2C3143;
margin: 8px 0 24px 0;
`;

const numberError = styled.h2`

font-family: Barlow;
font-size: 18px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;

/* or 128% */

display: flex;
align-items: center;
margin: 8px 0 24px 0;
color: #2C3143;
`;
interface TextProps{
  styleSheet?: unknown;
  children: unknown;
  p3?: unknown;
  h1?: unknown;
  h2?: unknown;
  h3?: unknown;
  h4?: unknown;
  h5?: unknown;
  h2Span?: unknown;
  plogin?: unknown;
  className?: unknown;
  h1Error?: unknown;
  h2Error?: unknown;
  msgError?: unknown;
  numberError?: unknown;
  dataCy?: string
}

const Text = (props: TextProps)=>{
  const styleSheetText = css`
    ${renderCSS(props.styleSheet, 'xs')}
  `;

  let AuxText = StyledText;

  if (props.p3){
    AuxText = p3;
  }

  if (props.h1){
    AuxText = h1;
  }

  if (props.h2){
    AuxText = h2;
  }

  if (props.h3){
    AuxText = h3;
  }

  if (props.h2Span){
    AuxText = h2Span;
  }

  if (props.h4){
    AuxText = h4;
  }

  if (props.h5){
    AuxText = h5;
  }

  if (props.plogin){
    AuxText = plogin;
  }

  if (props.h1Error){
    AuxText = h1Error;
  }

  if (props.h2Error){
    AuxText = h2Error;
  }

  if (props.msgError){
    AuxText = msgError;
  }

  if (props.numberError){
    AuxText = numberError;
  }

  return (
    <AuxText
      className={props.className}
      {...props}
      styleSheetText={styleSheetText}
      data-cy={props.dataCy}>
      {props.children}
    </AuxText>
  );
};

export default Text;

