import styled, { keyframes } from 'styled-components';
// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: .7em solid rgba(254, 175, 107, 0.4);
  border-right: .7em solid rgba(254, 175, 107, 0.4);
  border-bottom: .7em solid rgba(254, 175, 107, 0.4);
  border-left: .7em solid #F8933B;

  border-radius: 50%;
  width: 5em;
  height: 5em;

  animation: ${rotate} 1s linear infinite;
`;

const Loader = ()=>{
  return <StyledLoader/>;
};

export default Loader;

