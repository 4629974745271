import styled, { css } from 'styled-components';

const StyledIcon = styled.span`
  display: inline-block;
  mask: ${({ src }) => `url(${src}) no-repeat center`};
  width: 40px;
  height: 40px;
  margin-right: 8px;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'initial'};
  background-color: ${({ color }) => color};
  ${({ IconCss }) => css`${IconCss}`};
  ${({ active })=>!active ? 'mask: none;': ''}
  ${({ active })=>!active ? 'background-color: transparent;': ''}
  transform: ${({ rotate })=>rotate ? 'rotate(180deg)': ''};
  transition: transform 150ms ease;

  ${({ isButtonHover }) => isButtonHover && css`
    background-color: #F9F9F9;
`}

`;

StyledIcon.defaultProps = {
  src: '',
  color: '#5F698C',
  active: true
};

export interface IconProps {
  src: string;
  color?: string;
  IconCss?: unknown;
  active?: boolean;
  onClick?: any;
  rotate?: boolean;
  isButtonHover?: boolean;
}

const Icon = (props: IconProps) => {
  return <StyledIcon
    src={`/icons/sr-icons/${props.src !== undefined ? props.src : 'back'}.svg`}
    color={(!props.active ? 'transparent' : props.color)}
    active={props.active}
    IconCss={props.IconCss}
    onClick={props.onClick}
    rotate={props.rotate}
    isButtonHover={props.isButtonHover}/>;
};

export default Icon;
