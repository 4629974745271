import styled, { css } from 'styled-components';

const TooltipHoverCSS = css`
  &:hover::after {
    top: 85%;
    right: 0;
    opacity: 1;
    margin-top: 12px;
    pointer-events: all;
  }

  &:hover:before {
    top: 85%;
    right: 15px;
    opacity: 1;
    margin-top: 0;
    border-color: transparent transparent #222 transparent;
  }

  &:after, &:before {
    top: 90%;
    right: 15px;
    margin-top: 12px;
  }
`;

const TooltipClickCSS = css`
  &:active:not(:disabled), :focus {
    opacity: 1;
    pointer-events: all;
  }
  &:active:not(:disabled):before, :focus:before {
    top: 85%;
    right: 15px;
  }
`;

const StyledTooltip = styled.p`
  position: relative;

  ${({ hover })=>hover ? TooltipHoverCSS : ''}
  ${({ click })=>click ? TooltipClickCSS : ''}

  &::before{
    position: absolute;
    opacity: 0;
    content: '';
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
  }

  &::after {
    position: absolute;
    box-shadow:  0px 4px 30px rgba(11, 23, 67, 0.15);
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    content: attr(aria-label);
    background: #272A35;
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    z-index: 30;
  }
`;

StyledTooltip.defaultProps = {
  children: '',
  hover: true
};

export interface TooltipProps {
  children: any;
  hover?: string;
  click?: string;
  text?: string;
}

const Tooltip = (props: TooltipProps)=>{
  return (
    <StyledTooltip
      {...props}
      role="tooltip"
      aria-label={props.text}>
      {props.children}
    </StyledTooltip>
  );
};

export default Tooltip;

