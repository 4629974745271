import renderCSS from '@src/utils/renderCSS';
import { useState } from 'react';
import styled, { css } from 'styled-components';

export const StyledErrorInput = styled.p`
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  /* or 136% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Sem_Red_400 */

  color: #EC5050;
  margin-top: 1rem;
  flex-wrap: wrap;
  max-width: ${({ width })=> width ? width : '256px'};
  ${props => props.styleSheetErrorInput}
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-size: 14px;
  }
`;

interface ErrorInputProps{
  styleSheet?: object;
  children: string;
}

export const ErrorInput =({ children, styleSheet }: ErrorInputProps)=>{

  const styleSheetErrorInput = css`
    ${renderCSS(styleSheet, 'xs')}
  `;

  return <StyledErrorInput
    styleSheetErrorInput={styleSheetErrorInput}>
    { children }
  </StyledErrorInput>;
};

export const StyledInfoInput = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #5F698C;

  margin-top: 1rem;
  flex-wrap: wrap;
  max-width: ${({ width })=> width ? width : '256px'};
  ${props => props.styleSheetInfoInput}
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-size: 14px;
  }
`;

interface InfoInputProps {
  styleSheet?: object;
  children: string | JSX.Element
}

export const InfoInput =({ children, styleSheet }: InfoInputProps)=>{

  const styleSheetInfoInput = css`
    ${renderCSS(styleSheet, 'xs')}
  `;

  return <StyledInfoInput
    styleSheetInfoInput={styleSheetInfoInput}>
    { children }
  </StyledInfoInput>;
};

const StyledTextInput = styled.input`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #A2ABCB;

  background: #FCF7F2;
  border:  ${({ inValid })=> inValid || inValid === undefined
    ? '2px solid #4AA1FF' : '2px solid red'};
  border-radius: 8px;
  height: 72px;
  box-sizing: border-box;
  width: ${({ widthInput })=>widthInput ? widthInput : '256px'};
  text-align: center;

  margin-top: 40px;
  ::placeholder{
    color: #A2ABCB;
  }

  &:not(:last-child){
    margin-right: ${({ marginRight })=> marginRight ? marginRight : '6px'};
  }

  ${props => props.styleSheetTextInput}

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    width: ${({ widthInput })=>widthInput ? widthInput : '100%'};

    margin-top: 8px;
  }
`;

interface TextInputProps {
  styleSheet?: object;
  placeholder?: string;
  labelLeft?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: any)=> void;
  value: unknown;
  isValid?: boolean;
  onFocus?: ()=>void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (event: any)=> void;
  // eslint-disable-next-line no-unused-vars
  onKeyPress?: (event: any)=> void;
  maxLength?: number;
  width?: string;
  widthInput?: string;
  marginRight?: string;
  autoFocus?: boolean;
  readonlyInput?: boolean;
  dataCy?: string;
}

const Label = styled.label`
  margin-top: 40px;
`;

const Content = styled.div`
  width: ${({ width })=>width};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    min-width: ${({ width })=>width ? width : '100vw'};
    padding: 0 16px;
  }
`;

const TextInput = ({
  styleSheet,
  placeholder,
  labelLeft,
  onChange,
  value,
  isValid,
  onFocus,
  onBlur,
  onKeyPress,
  maxLength,
  width,
  marginRight,
  widthInput,
  autoFocus,
  readonlyInput,
  dataCy
}: TextInputProps)=>{
  const [touched, setTouched] = useState(false);

  const styleSheetTextInput = css`
    ${renderCSS(styleSheet, 'xs')}
  `;

  return (<Content width={width}>
    <StyledTextInput
      data-cy={dataCy}
      readOnly={readonlyInput}
      autoFocus={autoFocus}
      styleSheet={styleSheet}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      styleSheetTextInput={styleSheetTextInput}
      isValid={
        (typeof isValid !== 'undefined' && isValid && touched) ||
        (!isValid && !touched)||
        (isValid && !touched)
      }
      onFocus={()=>{
        if (onFocus){
          onFocus();
        }
        setTouched(true);
      }}
      onBlur={onBlur}
      maxLength={maxLength}
      width={width}
      widthInput={widthInput}
      marginRight={marginRight}
      onKeyPress={onKeyPress}/>
    {labelLeft && <Label>{labelLeft}</Label>}
  </Content>);
};

export default TextInput;

