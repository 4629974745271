import styled from 'styled-components';

const StyledFooter = styled.div<{initInfo: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: .1;
  min-width: 100%;
  height: 80px;
  padding: 0 80px;
  background-color: ${({ theme })=>theme.background.primary['100']};
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    position: ${({ initInfo })=>initInfo ? 'relative' : 'absolute'};
    bottom: 55px;
    left: 0;
    right: 0;
    max-width: initial;
    z-index: 10;
    padding: 0 16px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-content: center;
    justify-content: center;
    display: flex;
    height: 148px;
    flex-direction: column;
  }
`;

export interface ProgressbarProps {
  children: unknown;
  initInfo: boolean;
}

const Footer = ({ children, initInfo }: ProgressbarProps)=>{
  return (
    <StyledFooter initInfo={initInfo}>
      {children}
    </StyledFooter>
  );
};

export default Footer;

