import styled from 'styled-components';
import { Icon } from '..';

const SytledCheckbox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 21px;
  min-height: 21px;
  background: ${({ active })=>active ? '#4AA1FF' : '#FCF7F2'};
  border: ${({ active })=>active ? '2px solid #4AA1FF' : '2px solid #5F698C'};
  border-radius: 5px;
  margin-right: 8px;
`;

interface CheckboxProps{
  active: boolean;
  onClick: ()=>void;
}

const Checkbox =({ active, onClick }: CheckboxProps) =>{
  return (
    <SytledCheckbox
      active={active}
      onClick={onClick}>
      {active && <Icon
        src='check'
        active={active}
        color={'#FCF7F2'}
        IconCss={'width: 10px; height: 10px; margin: 0'}/>}
    </SytledCheckbox>
  );
};

export default Checkbox;
