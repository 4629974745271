/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Progressbar } from '..';

const LevelbarDefault = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ active, color })=> active ? color : '#D6E0EE'};
`;

interface LevelbarProps{
  score: number,
  color: string,
  categoryName?: string,
}

const Levelbar = ({ categoryName, score, color }: LevelbarProps)=>{
  const [width, setWidth] = useState(window.innerWidth);
  //TODO : get score-ranges cms
  const levelScore = [
    { id: 1, name: 'Atenção', maxScore:  49 , minScore: 0, category : 'geral' },
    { id: 2, name: 'Moderado', maxScore: 85 , minScore: 50, category : 'geral' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  86, category : 'geral' },
    { id: 1, name: 'Atenção', maxScore: 40 , minScore: 0, category : 'alimentacao' },
    { id: 2, name: 'Moderado', maxScore: 70 , minScore: 41, category : 'alimentacao' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  71, category : 'alimentacao' },
    { id: 1, name: 'Atenção', maxScore: 30 , minScore: 0, category : 'atividade-fisica' },
    { id: 2, name: 'Moderado', maxScore: 70 , minScore: 31, category : 'atividade-fisica' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  71, category : 'atividade-fisica' },
    { id: 1, name: 'Atenção', maxScore: 55 , minScore: 0, category : 'sono' },
    { id: 2, name: 'Moderado', maxScore: 77, minScore: 56, category : 'sono' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  78, category : 'sono' },
    { id: 1, name: 'Atenção', maxScore: 75 , minScore: 0, category : 'controle-de-toxicos' },
    { id: 2, name: 'Moderado', maxScore: 85 , minScore: 76, category : 'controle-de-toxicos' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  86, category : 'controle-de-toxicos' },
    { id: 1, name: 'Atenção', maxScore: 60 , minScore: 0, category : 'saude-mental' },
    { id: 2, name: 'Moderado', maxScore: 80 , minScore: 61, category : 'saude-mental' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  81, category : 'saude-mental' },
    { id: 1, name: 'Atenção', maxScore: 60 , minScore: 0, category : 'saude-social' },
    { id: 2, name: 'Moderado', maxScore: 75 , minScore: 61, category : 'saude-social' },
    { id: 3, name: 'Saúdavel', maxScore: 100, minScore:  76, category : 'saude-social' },
  ];

  useEffect(()=>{
    const resizeListener = ()=> {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', resizeListener);
  }, []);

  return (
    <Box
      styleSheet={{
        flex: 'initial'
      }}>
      <Progressbar
        level
        percent={score}
        color={color}/>
      <Box
        styleSheet={{
          flex: 'initial',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '-26px'
        }}>
        {levelScore.filter(scoreRange => scoreRange.category === categoryName).map((res, index)=>{
          return (
            <Box
              key={res.id}
              styleSheet={{
                marginRight: (levelScore.length === index+1 ? 0 : '-15px'),
                minWidth: (index === 0 ? res.maxScore+'%' : (res.maxScore-res.minScore+1)+'%'),
                display: 'flex',
                flexDirection: (index %2 !== 0 && width <= 600
                  ? 'column-reverse'
                  : 'column'),
                minHeight: '55.8px',
                fontSize: '14px'
              }}>
              <Box
                styleSheet={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <LevelbarDefault
                  active={score>=res.minScore|| index === 0}
                  color={color}/>
                {levelScore.length === index+1 &&
                <LevelbarDefault
                  active={score===100}
                  color={color}/>}
              </Box>
              <Box
                styleSheet={{
                  justifyContent: (index %2 !== 0 && width <= 600
                    ? 'center'
                    : 'flex-end'),
                  marginTop: (index %2 !== 0 && width <= 600
                    ? '-55px'
                    : 'initial'),
                  alignItems: 'flex-start'
                }}>
                {res.name}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

Levelbar.defaultProps = {
  color: '#50D19F'
};

export default Levelbar;
