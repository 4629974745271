import renderCSS from '@src/utils/renderCSS';
import styled from 'styled-components';

export interface BoxProps {
  className?: unknown;
  styleSheet?: object;
  children?: unknown;
  borders?: unknown;
  display?: unknown;
  flexbox?: unknown;
  palette?: unknown;
  positions?: unknown;
  shadows?: unknown;
  sizing?: unknown;
  spacing?: unknown;
  typography?: unknown;
  p?: object,
  padding?: unknown;
  row?: unknown;
  justifyContent?: unknown;
  minHeight?: unknown;
  onClick?: unknown;
  ref?: unknown;
}

/* eslint-disable max-len */
const spacing = (factor = 2) => {
  return `${0.25 * factor}rem`;
};

const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const up = key => `@media (min-width:${values[key]}px)`;

export const StyledBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${props => props.row ? 'row' : 'column'};
  color: ${props => props.color};
  background-color: ${props => props.bgcolor};

  justify-content: ${props => props.justifyContent};
  min-height: ${props => props.minHeight};

  align-items: ${props => props.alignItems};
  padding: ${props => props.padding};

  ${props => {
    if (props.p) {
      return Object.keys(props.p).map(res => {
        if (values[res] === 0) {
          return `padding: ${spacing(props.p[res])}`;
        }

        return `${up(props.p[res])} {padding: ${spacing(props.p[res])}}`;
      });
    }
  }}
  ${props => props.styleSheet ? renderCSS(props.styleSheet, 'xs') : ''}
`;

StyledBox.defaultProps = {
  theme: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: key => `@media (min-width:${values[key]}px)`
  }
};

const Box = (props: BoxProps) => {
  return (
    <StyledBox
      className={props.className}
      {...props}>
      {props.children}
    </StyledBox>
  );
};

export default Box;

