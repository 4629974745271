import renderCSS from '@src/utils/renderCSS';
import { theme } from '@theme';
import styled, { css } from 'styled-components';
import { Icon } from '..';
import { useState } from 'react';

const StyledButton = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 16px;
  width:418px;
  min-width: 328px;
  max-width: 418px;
  min-height: 56px;
  margin-top: 16px;
  cursor: pointer;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  background: ${({ theme, main }) => theme.button[main]?.background};
  border: ${({ theme, main }) => theme.button[main].border};
  box-shadow: ${({ theme, main }) => theme.button[main].boxShadow};
  border-radius: ${({ theme, main }) => theme.button[main].borderRadius};
  box-sizing: ${({ theme, main }) => theme.button[main].boxSizing};
  filter: ${({ theme, main }) => theme.button[main].filter};

  color: ${({ theme, main }) => theme.button[main].color};
  font-family: ${({ theme, main }) => theme.button[main].fontFamily};
  font-style: ${({ theme, main }) => theme.button[main].fontStyle};
  font-weight: ${({ theme, main }) => theme.button[main].fontWeight};
  font-size: ${({ theme, main }) => theme.button[main].fontSize};
  line-height: ${({ theme, main }) => theme.button[main].lineHeight};

  :active:not(:disabled), :focus{
    color: ${({ theme, main }) => theme.button[main].active?.color};
    border: ${({ theme, main }) => theme.button[main].active?.border};
    background: ${({ theme, main }) => theme.button[main].active?.background};
    box-shadow: ${({ theme, main }) => theme.button[main].active?.boxShadow};
    border-radius: ${({ theme, main }) => {
    return theme.button[main].active?.borderRadius;
  }};
  }

  :disabled {
    color: ${({ theme, main }) => theme.button[main].disabled?.color};
    background: ${({ theme, main }) => theme.button[main].disabled?.background};
    border: ${({ theme, main }) => theme.button[main].disabled?.border};
    border-radius: ${({ theme, main }) => {
    return theme.button[main].disabled?.borderRadius;
  }};
  }

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    max-width: 328px;
  }

  @media (min-width: ${({ theme })=>theme.breakpoints.sm}px){

    :hover:not(:disabled) {
      color: ${({ theme, main }) => theme.button[main].hover?.color};
      background: ${({ theme, main }) => theme.button[main].hover?.background};
      border-radius:
        ${({ theme, main }) => theme.button[main].hover?.borderRadius};
      border: ${({ theme, main }) => theme.button[main].hover?.border};
    }
  }

  margin-right: ${({ marginRight }) => marginRight ? marginRight : '16px'};
  /* Color the border and text with theme.main */
  ${props => props.styleSheetBox}
`;

// We are passing a default theme for
// Buttons that arent wrapped in the ThemeProvider

StyledButton.defaultProps = {
  main: 'primary',
  theme,
  onClick: () => null
};

/**
 * Primary UI component for user interaction
 */
export interface ButtonProps {
  label: string;
  onClick: unknown;
  main?: string;
  disabled?: boolean;
  iconRight?: string;
  iconLeft?: string;
  theme?: any;
  styleSheet?: object;
  className?: unknown;
  isSmall?: boolean;
  dataCy?: string;
}

const Button = (props: ButtonProps) => {

  const [isButtonHover, setIsButtonHover] = useState(false);

  const IconCss = `
    mask-size: 28px;
    width: 28px;
    height: 28px;
    background-color: ${props.disabled
    ? props.theme.button[props.main].disabled.color
    : props.theme.button[props.main].color};
  `;

  let auxButtonScalar: object = props.label && props.label.length === 1
    ? {
      maxHeight: '40px',
      minHeight: '40px',
      height: '40px',
      width: '40px',
      minWidth: 'initial',
      padding: 0,
    }
    : {};

  if (props.isSmall){
    auxButtonScalar = {
      ...auxButtonScalar,
      minWidth: 'initial',
      width: '145px'
    };
  }

  const styleSheetBox = css`
    ${renderCSS({ ...props.styleSheet, ...auxButtonScalar }, 'xs')}
  `;

  return (
    <StyledButton
      onMouseEnter={() => setIsButtonHover(true)}
      onMouseLeave={() => setIsButtonHover(false)}
      className={props.className}
      data-cy={props.dataCy}
      theme={props.theme}
      main={props.main}
      onClick={props.onClick}
      disabled={props.disabled}
      styleSheetBox={styleSheetBox}>
      {props.iconLeft && !props.isSmall &&
      <Icon
        active={props.iconLeft !== undefined}
        src={props.iconLeft}
        IconCss={IconCss}
        isButtonHover={isButtonHover} />}
      {props.label}
      {props.iconRight &&
      <Icon
        active={props.iconRight !== undefined}
        src={props.iconRight}
        IconCss={IconCss}
        isButtonHover={isButtonHover}/>}
    </StyledButton>);
};

Button.defaultProps = StyledButton.defaultProps;

export default Button;

